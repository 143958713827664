import {request} from "../../util/Request"
import qs from "qs"

export default {
    data() {
        return {
            exportModal: {
                id: null,
                visibility: false,
                columns: [
                    {id: 'week_number', label: this.$t('title.weekNumber'), show: true},
                    {id: 'start_of_week', label: this.$t('title.startOfWeek'), show: true},
                    {id: 'end_of_week', label: this.$t('title.endOfWeek'), show: true},
                    {id: 'order_country', label: this.$t('title.orderCountry'), show: true},
                    {id: 'pick_up_location', label: this.$t('title.pickUpLocation'), show: true},
                    {id: 'model', label: this.$t('title.model'), show: true},
                    {id: 'version_code', label: this.$t('title.versionCode'), show: true},
                    {id: 'number_of_vehicles', label: this.$t('title.numberOfVehicles'), show: true},
                ],
                headers: {
                    [this.$t('column.weekNumber')]: 'week_number',
                    [this.$t('column.startOfWeek')]: 'start_of_week',
                    [this.$t('column.endOfWeek')]: 'end_of_week',
                    [this.$t('column.orderCountry')]: 'order_country',
                    [this.$t('column.pickUpLocation')]: 'pick_up_location',
                    [this.$t('column.model')]: 'model',
                    [this.$t('column.versionCode')]: 'version_code',
                    [this.$t('column.numberOfVehicles')]: 'number_of_vehicles',
                }
            },
        }
    },
    methods: {
        handleExcelExportClick(id) {
            this.exportModal.id = id
            this.exportModal.visibility = true
        },
        async handleExcelExportSubmitClick() {
            let columns = _.filter(this.exportModal.columns, (item) => item.show).map(item => item.id)
            const headers = {}
            _.map(this.exportModal.headers, (index, item) => {
                if(index && _.includes(columns, index)) {
                    headers[item] = index
                }
            })
            this.exportModal.headers = headers

            const extraParams = this.getExtraParams() // from listingMixin
            let newListQueryParams = this.listQueryParams // from listingMixin
            const params = {...newListQueryParams, ...extraParams}
            const response = await request({
                method: "get",
                url: 'order/drafts/excel',
                params: params,
                paramsSerializer: ((params) => qs.stringify(params)),
            })

            const {data} = response
            const rows = _.map(data, (item, index) => _.pick({
                week_number:  this.$global.val(item.week_number),
                start_of_week:  this.$global.val(item.start_of_week),
                end_of_week:  this.$global.val(item.end_of_week),
                order_country: this.$global.val(item.order_country),
                pick_up_location: this.$global.val(item.city),
                model: this.$global.val(item.model_code),
                version_code: this.$global.val(item.version_type),
                number_of_vehicles: this.$global.val(item.total_orders),
            }, columns))
            return rows;
        },
        handleExcelExportCancelClick() {
            this.exportModal.visibility = false
            this.exportModal.id = null
        },
        handleExcelExportSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = true
            })
        },
        handleExcelExportUnSelectAllClick() {
            _.map(this.exportModal.columns, (item, index) => {
                this.exportModal.columns[index].show = false
            })
        },
    }
}
