<template>
    <div v-if="show">
        <b-card no-body>
            <b-tabs card fill pills>
                <b-tab :title="$t('title.chart')" active>
                    <b-card-text>
                        <form @submit.prevent="handleReportFilterSubmit">
                            <b-row>
                                <b-col sm="12">
                                    <b-row>
                                        <b-col sm="8">
                                            <b-row>
                                                <b-col sm="12" md="4" lg="4">
                                                    <b-form-group :invalid-feedback="formErrors.first('countries')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.countries"
                                                            :placeholder="$t('input.destinationCountries')"
                                                            v-model="chartFormFields.countries"
                                                            :class="[{'invalid is-invalid': (formErrors.has('countries'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" md="4" lg="4">
                                                    <b-form-group :invalid-feedback="formErrors.first('brands')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.brands"
                                                            :placeholder="$t('input.brands')"
                                                            v-model="chartFormFields.brands"
                                                            :class="[{'invalid is-invalid': (formErrors.has('brands'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" md="4" lg="4">
                                                    <b-form-group :invalid-feedback="formErrors.first('models')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.models"
                                                            :placeholder="$t('input.models')"
                                                            v-model="chartFormFields.models"
                                                            :class="[{'invalid is-invalid': (formErrors.has('models'))}]"
                                                        />
                                                    </b-form-group>
                                                </b-col>
                                                <b-col sm="12" class="text-danger">
                                                    <small>{{$t('msc.pleaseNoteThatTheVolumesAreIndicativeAndNonBinding')}}</small>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col sm="1">
                                            <b-button
                                                block
                                                size="sm"
                                                type="submit"
                                                variant="primary"
                                                :disabled="global.pendingRequests > 0"
                                                v-b-tooltip.hover :title="$t('button.title.filterRecords')"
                                            >
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                {{$t('button.apply')}}
                                            </b-button>
                                        </b-col>
                                        <b-col sm="1" class="pl-0">
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('orderdraftsview')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                        </b-col>
                                        <b-col v-if="0 == 1" sm="3" class="text-right">
                                            <b-button size="sm" type="button" variant="info" @click="handlePrevClick()"
                                                      :disabled="global.pendingRequests > 0">
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                {{$t('button.previous')}}
                                            </b-button>
                                            <b-button size="sm" type="button" variant="secondary"
                                                      @click="handleNextClick()"
                                                      class="ml-2"
                                                      :disabled="global.pendingRequests > 0">
                                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                                             size="12px"
                                                             v-if="global.pendingRequests > 0"></clip-loader>
                                                {{$t('button.next')}}
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                        <template v-if="chartDataKeys.length > 0">
                            <b-row class="p-3 bg-gray-3">
                                <b-col sm="3">
                                    <h5 class="m-0 p-0">
                                        {{(startYear === endYear) ? startYear : `${startYear}-${endYear}`}}
                                    </h5>
                                </b-col>
                                <b-col sm="1" v-for="(label, index) in chartDataKeys" :key="index" class="text-right">
                                    <h5 :class="(_.find(weekAcceptance, {identifier: label})) ? _.find(weekAcceptance, {identifier: label}).class + ' m-0 p-0 fs-13' : 'm-0 p-0 fs-13' "
                                        :title="(_.find(weekAcceptance, {identifier: label})) ? _.find(weekAcceptance, {identifier: label}).title : '' ">
                                        <span
                                            :style="(_.find(weekAcceptance, {identifier: label}) && _.find(weekAcceptance, {identifier: label}).class ? 'border-bottom: 3px solid' : '')">{{label}}</span>
                                    </h5>
                                </b-col>
                            </b-row>
                            <template v-for="(country, index) in chartDataModelGrouped">
                                <b-row class="p-3 bg-gray-3">
                                    <b-col sm="12" v-b-toggle="'country-'+index">
                                        <h5 class="m-0 p-0">
                                            <span class="when-open"><i class="fe fe-minus"></i></span><span
                                            class="when-closed"><i class="fe fe-plus"></i></span>
                                            {{country.country}}
                                            <span> <country-sum :country="country"></country-sum> </span>
                                        </h5>
                                    </b-col>
                                    <b-col sm="12">
                                        <b-collapse :id="'country-'+index" visible>
                                            <div class="p-2 bg-gray-2">
                                                <b-row v-for="(pil, pilIndex) in country.items" :key="pilIndex">
                                                    <b-col sm="12" v-b-toggle="'model-'+(index+'-'+pilIndex)">
                                                        <span class="text-size-1-2em">
                                                            <span class="when-open"><i
                                                                class="fe fe-minus"></i></span><span
                                                            class="when-closed"><i class="fe fe-plus"></i></span>
                                                            {{pil.city}}
                                                            <span> <pick-up-sum :item="pil.items"></pick-up-sum> </span>
                                                        </span>
                                                    </b-col>
                                                    <b-col sm="12">
                                                        <b-collapse :id="'model-'+(index+'-'+pilIndex)"
                                                                    class="bg-gray-1" visible>
                                                            <div class="p-2">
                                                                <b-row v-for="(mdl, mdlIndex) in pil.items"
                                                                       :key="mdlIndex">
                                                                    <b-col sm="3">{{mdl.model_code}}</b-col>
                                                                    <template
                                                                        v-for="(week, weekIndex) in chartDataKeys">
                                                                        <b-col sm="1" class="text-right">
                                                                            {{ (_.find(mdl.items, {identifier: week})) ?
                                                                            _.find(mdl.items, {identifier:
                                                                            week}).total_orders : 0 }}
                                                                        </b-col>
                                                                    </template>
                                                                </b-row>
                                                            </div>
                                                        </b-collapse>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-col>
                                <b-row>
                                    <b-col><strong>{{ $t('title.lastUpdatedAt') }}:</strong> {{(lastOrderDraft &&
                                        lastOrderDraft.updated_at ?
                                        $global.utcDateToLocalDate(lastOrderDraft.updated_at) : '')}}
                                    </b-col>
                                </b-row>
                            </b-col>
                        </template>
                    </b-card-text>
                </b-tab>
                <b-tab :title="$t('title.list')">
                    <b-card-text>
                        <div class="card" v-show="operation !== 'detail'">
                            <div class="card-header card-header-flex pb-2">
                                <div class="w-100 mt-2">
                                    <div class="row">
                                        <div class="col-8">
                                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                                <strong>
                                                    <template v-if="operation === null">{{$t('title.orderDrafts')}}
                                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                                    </template>
                                                    <template v-else>{{ $t(operationTitle) }}</template>
                                                </strong>
                                            </h5>
                                        </div>
                                        <div class="col-4 text-right">
                                            <div v-if="operation === null">
                                                <div class="mt-3">
                                                    <!-- Using components -->
                                                    <b-input-group class="mt-3">
                                                        <b-form-input type="search" class="form-control form-control-sm"
                                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                                      v-on:keyup.enter="handleSearch"
                                                                      v-model="search"></b-form-input>
                                                        <b-input-group-append>
                                                            <b-button size="sm"
                                                                      :title="$t('button.title.filterRecords')"
                                                                      variant="outline-info"
                                                                      @click="filters.visible = !filters.visible"
                                                                      v-b-tooltip.hover
                                                                      v-if="$global.hasPermission('ordersview')">
                                                                <i class="fa fa-filter"></i>
                                                            </b-button>
                                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                                      variant="outline-info"
                                                                      @click="handleResetFilterClick()"
                                                                      v-b-tooltip.hover>
                                                                <i class="fa fa-refresh"></i>
                                                            </b-button>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <b-button variant="warning" size="sm" class="mt-3"
                                                          @click="handleOperationClose()"
                                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                                </b-button>
                                            </div>
                                        </div>
                                    </div><!-- /.row -->
                                </div><!-- /.w-100 -->
                            </div><!-- /.card-header -->
                            <div class="card-body p-0">
                                <div class="order-drafts-table" >
                                    <b-table hover responsive
                                             ref="table"
                                             :busy="listingLoading"
                                             :items="dataSource"
                                             :fields="columns"
                                             :sort-by.sync="sortField"
                                             @sort-changed="handleSortChange"
                                            class="m-h-150">
                                        <template v-slot:cell(dealer_id)="{ detailsShowing, item, index, rowSelected}">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                            </template>
                                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                            </a>
                                            {{ (item.dealer_id ? item.dealer_id : '') }}
                                        </template>
                                        <template v-slot:cell(client_id)="record">
                                            {{ (record.item.client ? record.item.client.company_name : '') }}
                                        </template>
                                        <template v-slot:cell(order_type)="record">
                                            <od-type :type="record.item.order_type"/>
                                        </template>
                                        <template v-slot:cell(status)="record">
                                            <od-status :status="record.item.status"/>
                                        </template>
                                        <template v-slot:cell(load_status)="record">
                                            <a href="javascript:;" @click="() => handleLoadStatusClick(record.item)">
                                                <ol-status :status="record.item.load_status"/>
                                            </a>
                                        </template>
                                        <template v-slot:cell(updated_at)="record">
                                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                                        </template>
                                        <template v-slot:cell(action)="record">
                                            <a @click="setOperation('edit', record.item.id)"
                                               class="ml-1"
                                               :title="$t('button.title.editItem')"
                                               v-if="$global.hasPermission('ordersupdate')
                                                && (!record.item.load_status || (record.item.load_status == 8 || record.item.load_status == 9 || record.item.load_status <= 1))"
                                               v-b-tooltip.hover>
                                                <i class="fe fe-edit"></i>
                                            </a>
                                            <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none" no-caret v-if="$global.hasPermission('ordersupdate')">
                                                <template #button-content>
                                                    <i class="fe fe-more-vertical"></i>
                                                </template>
                                                <b-dropdown-item href="#"
                                                                 v-if="!record.item.call_off_date_importer && !record.item.reexport_call_off_date_importer"
                                                                 @click="handleDdUpdateClick(record.item, 'order/drafts/dd/update')">
                                                    {{$t('button.updateDDCall')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item href="#"
                                                                 v-if="!record.item.call_off_date_importer"
                                                                 @click="handleReexportUpdateClick(record.item, 'order/drafts/reexport/update')">
                                                    {{$t('button.updateReexportCall')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item href="#"
                                                                 v-if="
                                                         !record.item.dd_call_off_date_importer
                                                         && !record.item.reexport_call_off_date_importer
                                                         && (!record.item.load_status || record.item.load_status == 8 || record.item.load_status == 9 || record.item.load_status == 1)
                                                    "
                                                                 @click="handleCtcUpdateClick(record.item, 'order/drafts/ctc/update')">
                                                    {{$t('button.updateCtcCall')}}
                                                </b-dropdown-item>
                                                <b-dropdown-item href="#"
                                                                 v-if="
                                                         !record.item.dd_call_off_date_importer
                                                         && !record.item.reexport_call_off_date_importer
                                                         && (!record.item.load_status || record.item.load_status == 8 || record.item.load_status == 9 || record.item.load_status == 1)
                                                    "
                                                                 @click="handleCallOffDateUpdateClick(record.item, 'order/drafts/call/of/date/update')">
                                                    {{$t('button.updateCallOfDate')}}</b-dropdown-item>
                                            </b-dropdown>
                                            <a @click="setOperation('detail', record.item.id)"
                                               :title="$t('button.title.detailItem')"
                                               v-if="$global.hasPermission('orderdraftsview')"
                                               class="ml-1"
                                               v-b-tooltip.hover>
                                                <i class="icmn-info"></i>
                                            </a>
                                        </template>
                                        <template v-slot:row-details="{ item }">
                                            <b-card>
                                                <b-list-group flush>
                                                    <b-list-group-item>
                                                        <strong>{{$t('column.orderImportErrors')}}</strong>:
                                                        {{item.order_error_notes}}
                                                    </b-list-group-item>
                                                </b-list-group>
                                            </b-card>
                                        </template>
                                    </b-table><!-- /.b-table -->

                                    <div class="clearfix">
                                        <div class="float-left ">
                                            <b-form-select v-model="pagination.perPage"
                                                           :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                                        </div>
                                        <div class="float-right">
                                            <b-pagination
                                                v-model="pagination.current"
                                                :total-rows="pagination.total"
                                                :per-page="pagination.perPage"
                                                :first-text="$t('paginations.first')"
                                                :prev-text="$t('paginations.prev')"
                                                :next-text="$t('paginations.next')"
                                                :last-text="$t('paginations.last')"
                                            ></b-pagination>
                                        </div><!-- /.pull-right -->
                                    </div><!-- /.clearfix -->
                                </div><!-- /.order-drafts-table -->
                                <div class="order-drafts-operation">
                                    <a-drawer
                                        placement="right"
                                        :width="'950px'"
                                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                                        :closable="false"
                                        @close="handleOperationClose"
                                        :visible="operation !== null && operation !== 'detail'"
                                        :zIndex="10"
                                        :title="$t(operationTitle)"
                                    >
                                        <form @submit.prevent="handleSubmit" autocomplete="off" class="mb-5">
                                            <b-row>
                                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                                </b-col>

                                                <b-col v-show="global.pendingRequests <= 0" cols="12">
                                                    <b-row v-if="editOrderDraft && editOrderDraft.order_error_notes"
                                                           class="mb-2">
                                                        <b-col sm="12">
                                                            <p class="text-danger m-0"
                                                               v-for="(od, index) in editOrderDraft.order_error_notes.split('.')">
                                                                <template v-if="od">
                                                                    {{index+1}}. {{od}}
                                                                </template>
                                                            </p>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.orderCode')+' *'"
                                                                label-for="order_code"
                                                                :invalid-feedback="formErrors.first('order_code')"
                                                            >
                                                                <b-form-input
                                                                    id="order_code"
                                                                    v-model="formFields.order_code"
                                                                    type="text"
                                                                    :state="((formErrors.has('order_code') ? false : null))"
                                                                    ref="order_code"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.brandName')+' *'"
                                                                label-for="brand_name"
                                                                :invalid-feedback="formErrors.first('brand_name')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.brands"
                                                                    placeholder=""
                                                                    v-model="formFields.brand_id"
                                                                    @select="handleBrandSelect"
                                                                    :disabled="formFields.status == 2"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('brand_id'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.modelCode')+' *'"
                                                                label-for="model_code"
                                                                :invalid-feedback="formErrors.first('model_code')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="_.filter(dropdowns.models, (item) => item.brand_id === formFields.brand_id)"
                                                                    placeholder=""
                                                                    :disabled="formFields.status == 2"
                                                                    v-model="formFields.model_id"
                                                                    @select="handleModelSelect"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('model_id'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.factoryReferenceNumber')+' *'"
                                                                label-for="factory_reference_number"
                                                                :invalid-feedback="formErrors.first('factory_reference_number')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.manufacturerLocations"
                                                                    placeholder=""
                                                                    :disabled="formFields.status == 2"
                                                                    v-model="formFields.factory_reference_number"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('factory_reference_number'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.deliveryPlaceImporter')+' *'"
                                                                label-for="delivery_place_importer"
                                                                :invalid-feedback="formErrors.first('delivery_place_importer')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.supplierCompoundLocations"
                                                                    placeholder=""
                                                                    v-model="formFields.delivery_place_importer"
                                                                    :disabled="(formFields.status == 2 && !(!formFields.delivery_place_importer))"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('delivery_place_importer'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="3">
                                                            <b-form-group
                                                                :label="$t('input.versionType')+' *'"
                                                                label-for="version_type"
                                                                :invalid-feedback="formErrors.first('version_type')"
                                                            >
                                                                <b-form-input
                                                                    id="version_type"
                                                                    v-model="formFields.version_type"
                                                                    type="text"
                                                                    :state="((formErrors.has('version_type') ? false : null))"
                                                                    ref="version_type"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="3">
                                                            <b-form-group
                                                                :label="$t('input.category')+' *'"
                                                                label-for="category"
                                                                :invalid-feedback="formErrors.first('category')"
                                                            >
                                                                <b-form-input
                                                                    id="category"
                                                                    v-model="formFields.category"
                                                                    type="text"
                                                                    :state="((formErrors.has('category') ? false : null))"
                                                                    ref="category"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="3">
                                                            <b-form-group
                                                                :label="$t('input.fuelType')"
                                                                label-for="fuel_type"
                                                                :invalid-feedback="formErrors.first('fuel_type')"
                                                            >
                                                                <b-form-input
                                                                    id="fuel_type"
                                                                    v-model="formFields.fuel_type"
                                                                    type="text"
                                                                    :state="((formErrors.has('fuel_type') ? false : null))"
                                                                    ref="fuel_type"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="3">
                                                            <b-form-group
                                                                :label="$t('input.orderType')+' *'"
                                                                label-for="order_type"
                                                                :invalid-feedback="formErrors.first('order_type')"
                                                            >
                                                                <b-form-input
                                                                    id="order_type"
                                                                    v-model="formFields.order_type"
                                                                    type="text"
                                                                    :state="((formErrors.has('order_type') ? false : null))"
                                                                    ref="order_type"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.orderCountry')"
                                                                label-for="order_country"
                                                                :invalid-feedback="formErrors.first('order_country')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.countries"
                                                                    placeholder=""
                                                                    v-model="formFields.order_country"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('order_country'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.vinNumber')"
                                                                label-for="vin_number"
                                                                :invalid-feedback="formErrors.first('vin_number')"
                                                            >
                                                                <b-form-input
                                                                    id="vin_number"
                                                                    v-model="formFields.vin_number"
                                                                    type="text"
                                                                    :state="((formErrors.has('vin_number') ? false : null))"
                                                                    ref="vin_number"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.mpoNumber')"
                                                                label-for="mpo_number"
                                                                :invalid-feedback="formErrors.first('mpo_number')"
                                                            >
                                                                <b-form-input
                                                                    id="mpo_number"
                                                                    v-model="formFields.mpo_number"
                                                                    type="text"
                                                                    :state="((formErrors.has('mpo_number') ? false : null))"
                                                                    ref="mpo_number"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.destinationCountryImporter')"
                                                                label-for="destination_country_importer"
                                                                :invalid-feedback="formErrors.first('destination_country_importer')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.countries"
                                                                    placeholder=""
                                                                    v-model="formFields.destination_country_importer"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('destination_country_importer'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.invoiceNumberImporter')"
                                                                label-for="invoice_number_importer"
                                                                :invalid-feedback="formErrors.first('invoice_number_importer')"
                                                            >
                                                                <b-form-input
                                                                    id="invoice_number_importer"
                                                                    v-model="formFields.invoice_number_importer"
                                                                    type="text"
                                                                    :state="((formErrors.has('invoice_number_importer') ? false : null))"
                                                                    ref="invoice_number_importer"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.dealerId')"
                                                                label-for="dealer_id"
                                                                :invalid-feedback="formErrors.first('dealer_id')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.dealers"
                                                                    placeholder=""
                                                                    v-model="formFields.dealer_id"
                                                                    :disabled="(formFields.load_status != 8 && formFields.load_status != 9 && !(formFields.load_status <= 1))"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('dealer_id'))}]"
                                                                    @select="handleDealersSelect"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.deliveryPlaceDealer')"
                                                                label-for="delivery_place_dealer"
                                                                :invalid-feedback="formErrors.first('delivery_place_dealer')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.dealersLocations"
                                                                    placeholder=""
                                                                    v-model="formFields.dealer_location_id"
                                                                    :disabled="(formFields.load_status != 8 && formFields.load_status != 9 && !(formFields.load_status <= 1))"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('dealer_location_id'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.dealerName')"
                                                                label-for="dealer_name"
                                                                :invalid-feedback="formErrors.first('dealer_name')"
                                                            >
                                                                <b-form-input
                                                                    id="dealer_name"
                                                                    v-model="formFields.dealer_name"
                                                                    type="text"
                                                                    :state="((formErrors.has('dealer_name') ? false : null))"
                                                                    ref="dealer_name"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.dealerZone')"
                                                                label-for="dealer_zone"
                                                                :invalid-feedback="formErrors.first('dealer_zone')"
                                                            >
                                                                <b-form-input
                                                                    id="dealer_zone"
                                                                    v-model="formFields.dealer_zone"
                                                                    type="text"
                                                                    :state="((formErrors.has('dealer_zone') ? false : null))"
                                                                    ref="dealer_zone"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="6">
                                                            <b-form-group
                                                                :label="$t('input.destinationCountryDealer')"
                                                                label-for="destination_country_dealer"
                                                                :invalid-feedback="formErrors.first('destination_country_dealer')"
                                                            >
                                                                <treeselect
                                                                    :multiple="false"
                                                                    :options="dropdowns.countries"
                                                                    placeholder=""
                                                                    v-model="formFields.destination_country_dealer"
                                                                    :class="[{'invalid is-invalid': (formErrors.has('destination_country_dealer'))}]"
                                                                />
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="12">
                                                            <b-form-group
                                                                :label="$t('input.modelDescription')"
                                                                label-for="model_description"
                                                                :invalid-feedback="formErrors.first('model_description')"
                                                            >
                                                                <b-form-input
                                                                    id="model_description"
                                                                    v-model="formFields.model_description"
                                                                    type="text"
                                                                    :state="((formErrors.has('model_description') ? false : null))"
                                                                    ref="model_description"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.expectedProductionDate')"
                                                                label-for="expected_production_date"
                                                                :invalid-feedback="formErrors.first('expected_production_date')"
                                                            >
                                                                <b-form-datepicker
                                                                    id="expected_production_date"
                                                                    v-model="formFields.expected_production_date"
                                                                    type="text"
                                                                    placeholder=""
                                                                    reset-button
                                                                    :state="((formErrors.has('expected_production_date') ? false : null))"
                                                                    ref="expected_production_date"
                                                                    @focus="$event.target.select()"
                                                                    class="mb-2"></b-form-datepicker>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.actualProductionDate')"
                                                                label-for="actual_production_date"
                                                                :invalid-feedback="formErrors.first('actual_production_date')"
                                                            >
                                                                <b-form-datepicker
                                                                    id="actual_production_date"
                                                                    v-model="formFields.actual_production_date"
                                                                    type="text" placeholder=""
                                                                    reset-button
                                                                    :state="((formErrors.has('actual_production_date') ? false : null))"
                                                                    ref="actual_production_date"
                                                                    @focus="$event.target.select()"
                                                                    class="mb-2"></b-form-datepicker>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.manufacturerInvoiceDate')"
                                                                label-for="manufacturer_invoice_date"
                                                                :invalid-feedback="formErrors.first('manufacturer_invoice_date')"
                                                            >
                                                                <b-form-datepicker
                                                                    id="manufacturer_invoice_date"
                                                                    v-model="formFields.manufacturer_invoice_date"
                                                                    type="text" placeholder=""
                                                                    reset-button
                                                                    :state="((formErrors.has('manufacturer_invoice_date') ? false : null))"
                                                                    ref="manufacturer_invoice_date"
                                                                    @focus="$event.target.select()"
                                                                    class="mb-2"></b-form-datepicker>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                    <b-row>
                                                        <b-col sm="4">
                                                            <b-form-group
                                                                :label="$t('input.invoiceDateImporter')"
                                                                label-for="invoice_date_importer"
                                                                :invalid-feedback="formErrors.first('invoice_date_importer')"
                                                            >
                                                                <b-form-datepicker
                                                                    id="invoice_date_importer"
                                                                    v-model="formFields.invoice_date_importer"
                                                                    type="text" placeholder=""
                                                                    reset-button
                                                                    :state="((formErrors.has('invoice_date_importer') ? false : null))"
                                                                    ref="invoice_date_importer"
                                                                    @focus="$event.target.select()"
                                                                    class="mb-2"></b-form-datepicker>
                                                            </b-form-group>
                                                        </b-col><!--/b-col-->
                                                    </b-row>
                                                </b-col><!--/b-col-->
                                                <div class="drawer-footer">
                                                    <b-button
                                                        size="sm"
                                                        type="submit"
                                                        variant="primary"
                                                        :disabled="global.pendingRequests > 0"
                                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                                    >
                                                        <clip-loader style="display: inline" :loading="true"
                                                                     color="#fff"
                                                                     size="12px"
                                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                                        <i class="fa fa-save mr-1"></i>
                                                        {{$t('button.save')}}
                                                    </b-button>
                                                    <b-button variant="warning" class="ml-3"
                                                              size="sm" @click="handleOperationClose()"
                                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                    </b-button>
                                                </div>
                                            </b-row><!--/b-row-->
                                        </form><!--/form-->
                                    </a-drawer>
                                </div><!--/.order-drafts-operation-->
                                <div class="filter-container">
                                    <a-drawer
                                        placement="left"
                                        :width="'360px'"
                                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                                        :closable="false"
                                        @close="filters.visible = !filters.visible"
                                        :visible="!operation && filters.visible"
                                        :zIndex="10"
                                        :title="$t('title.advanceFilters')"
                                    >
                                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                            <b-row>
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.clients')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.clients"
                                                            placeholder=""
                                                            v-model="filters.clients"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.market')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="_.filter(dropdowns.countries, (item) => item.id === item.label)"
                                                            placeholder=""
                                                            v-model="filters.countries"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.orderType')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.orderType"
                                                            placeholder=""
                                                            v-model="filters.orderType"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.orderCode')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.orderCode"
                                                            placeholder=""
                                                            v-model="filters.orderCode"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.brands')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.brandName"
                                                            placeholder=""
                                                            v-model="filters.brandName"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.models')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="_.uniqBy(_.filter(dropdowns.modelCode,(item) => item.id === item.label),'label')"
                                                            placeholder=""
                                                            v-model="filters.modelCode"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.versionType')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="_.uniqBy(dropdowns.versionCode,'label')"
                                                            placeholder=""
                                                            v-model="filters.versionCode"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.status')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.status"
                                                            placeholder=""
                                                            v-model="filters.status"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.loadStatus')">
                                                        <treeselect
                                                            :multiple="true"
                                                            :options="dropdowns.loadStatus"
                                                            placeholder=""
                                                            v-model="filters.loadStatus"
                                                        />
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.fromAddedDate')"
                                                        label-for="fromDate">
                                                        <b-form-datepicker placeholder="" id="fromDate"
                                                                           v-model="filters.from_date"
                                                                           class="mb-2"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                                <b-col sm="12">
                                                    <b-form-group
                                                        :label="$t('input.toAddedDate')"
                                                        label-for="toDate">
                                                        <b-form-datepicker placeholder="" id="toDate"
                                                                           v-model="filters.to_date"
                                                                           class="mb-2"></b-form-datepicker>
                                                    </b-form-group>
                                                </b-col><!--/b-col-->
                                            </b-row>
                                            <div class="drawer-footer">
                                                <b-button size='sm' variant="info"
                                                          @click="filters.visible = !filters.visible"
                                                          class="mr-2" :title="$t('button.title.closePanel')"
                                                          v-b-tooltip.hover>
                                                    {{$t('button.close')}}
                                                </b-button>
                                                <b-button size='sm' variant="warning" @click="handleResetFilterClick"
                                                          class="mr-2"
                                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                                    {{$t('button.reset')}}
                                                </b-button>
                                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                                    {{$t('button.apply')}}
                                                </b-button>
                                            </div><!-- /.drawer-footer -->
                                        </form>
                                    </a-drawer>
                                </div><!-- /.filter-container -->
                            </div><!-- /.card-body-->
                        </div><!-- /.card -->
                    </b-card-text>
                </b-tab>
            </b-tabs>
        </b-card>
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- DD Call Off Date Importer -->
        <b-modal id="update-dd-call-date-importer-email" hide-footer size="md" v-model="ddUpdateModal.visibility">
            <template #modal-title>
                {{$t('title.updateDDCallDateImporter')}}
            </template>
            <form @submit.prevent="handleDdUpdateSubmit">
                <div class="d-block">
                    <b-form-group
                        :label="$t('input.ddLocationCode')"
                        label-for="dd_location"
                        :invalid-feedback="formErrors.first('dd_location')"
                    >
                        <b-form-input
                            id="dd_location"
                            v-model="ddUpdateModal.formFields.dd_location"
                            type="text"
                            :state="((formErrors.has('dd_location') ? false : null))"
                            ref="dd_location"
                            @focus="$event.target.select()"
                        ></b-form-input>
                    </b-form-group>
                </div>
                <div class="d-block">
                    <b-form-group
                        :label="$t('input.ddCallOffDateImporter')"
                        label-for="dd_call_off_date_importer"
                        :invalid-feedback="formErrors.first('dd_call_off_date_importer')"
                    >
                        <b-form-datepicker
                            id="dd_call_off_date_importer"
                            v-model="ddUpdateModal.formFields.dd_call_off_date_importer"
                            type="text" placeholder=""
                            reset-button
                            :state="((formErrors.has('dd_call_off_date_importer') ? false : null))"
                            ref="dd_call_off_date_importer"
                            @focus="$event.target.select()"
                            class="mb-2"></b-form-datepicker>
                    </b-form-group>
                </div>
                <hr>
                <div class="text-right">
                    <b-button
                        size="sm"
                        type="submit"
                        variant="primary"
                        :disabled="global.pendingRequests > 0"
                        v-b-tooltip.hover
                    >
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-save mr-1"></i>
                        {{$t('button.send')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="resetDdUpdateOperation()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div>
            </form>
        </b-modal><!-- /.update-dd-call-date-importer-email -->

        <!-- Reexport Call Off Date Importer -->
        <b-modal id="update-reexport-call-date-importer-email" hide-footer size="md" v-model="reexportUpdateModal.visibility">
            <template #modal-title>
                {{$t('title.updateReexportCallDateImporter')}}
            </template>
            <form @submit.prevent="handleReexportUpdateSubmit">
                <div class="d-block">
                    <b-form-group
                        :label="$t('input.reexportCallOffDateImporter')"
                        label-for="reexport_call_off_date_importer"
                        :invalid-feedback="formErrors.first('reexport_call_off_date_importer')"
                    >
                        <b-form-datepicker
                            id="reexport_call_off_date_importer"
                            v-model="reexportUpdateModal.formFields.reexport_call_off_date_importer"
                            type="text" placeholder=""
                            reset-button
                            :state="((formErrors.has('reexport_call_off_date_importer') ? false : null))"
                            ref="reexport_call_off_date_importer"
                            @focus="$event.target.select()"
                            class="mb-2"></b-form-datepicker>
                    </b-form-group>
                </div>
                <hr>
                <div class="text-right">
                    <b-button
                        size="sm"
                        type="submit"
                        variant="primary"
                        :disabled="global.pendingRequests > 0"
                        v-b-tooltip.hover
                    >
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-save mr-1"></i>
                        {{$t('button.send')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="resetReexportUpdateOperation()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div>
            </form>
        </b-modal><!-- /.update-reexport-call-date-importer-email -->

        <!-- Call Off Date Importer -->
        <b-modal id="update-call-date-importer-email" hide-footer size="md" v-model="callOffDateUpdateModal.visibility">
            <template #modal-title>
                {{$t('title.updateCallOffDateImporter')}}
            </template>
            <form @submit.prevent="handleCallOffDateUpdateSubmit">
                <div class="d-block">
                    <b-form-group
                        :label="$t('input.callOffDateImporter')"
                        label-for="call_off_date_importer"
                        :invalid-feedback="formErrors.first('call_off_date_importer')"
                    >
                        <b-form-datepicker
                            id="call_off_date_importer"
                            v-model="callOffDateUpdateModal.formFields.call_off_date_importer"
                            type="text" placeholder=""
                            reset-button
                            :state="((formErrors.has('call_off_date_importer') ? false : null))"
                            ref="call_off_date_importer"
                            @focus="$event.target.select()"
                            class="mb-2"></b-form-datepicker>
                    </b-form-group>
                </div>
                <hr>
                <div class="text-right">
                    <b-button
                        size="sm"
                        type="submit"
                        variant="primary"
                        :disabled="global.pendingRequests > 0"
                        v-b-tooltip.hover
                    >
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-save mr-1"></i>
                        {{$t('button.send')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="resetCallOffDateUpdateOperation()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div>
            </form>
        </b-modal><!-- /.update-call-date-importer-email -->

        <!-- CTC Call Off Date Importer -->
        <b-modal id="update-ctc-call-date-importer-email" hide-footer size="md" v-model="secondUpdateModal.visibility">
            <template #modal-title>
                {{$t('title.updateCtcCallDateImporter')}}
            </template>
            <form @submit.prevent="handleCtcUpdateSubmit">
                <div class="d-block">
                    <b-form-group
                        :label="$t('input.secondDeliveryPlaceImporter')"
                        label-for="second_delivery_place_importer"
                        :invalid-feedback="formErrors.first('second_delivery_place_importer')"
                    >
                        <b-form-input
                            id="second_delivery_place_importer"
                            v-model="secondUpdateModal.formFields.second_delivery_place_importer"
                            type="text"
                            :state="((formErrors.has('second_delivery_place_importer') ? false : null))"
                            ref="second_delivery_place_importer"
                            @focus="$event.target.select()"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        :label="$t('input.callOffDateImporter')"
                        label-for="call_off_date_importer"
                        :invalid-feedback="formErrors.first('call_off_date_importer')"
                    >
                        <b-form-datepicker
                            id="call_off_date_importer"
                            v-model="secondUpdateModal.formFields.call_off_date_importer"
                            type="text" placeholder=""
                            reset-button
                            :state="((formErrors.has('call_off_date_importer') ? false : null))"
                            ref="call_off_date_importer"
                            @focus="$event.target.select()"
                            class="mb-2"></b-form-datepicker>
                    </b-form-group>
                </div>
                <hr>
                <div class="text-right">
                    <b-button
                        size="sm"
                        type="submit"
                        variant="primary"
                        :disabled="global.pendingRequests > 0"
                        v-b-tooltip.hover
                    >
                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                     size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                        <i class="fa fa-save mr-1"></i>
                        {{$t('button.send')}}
                    </b-button>
                    <b-button variant="warning" class="ml-3"
                              size="sm" @click="resetCtcUpdateOperation()"
                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                    </b-button>
                </div>
            </form>
        </b-modal><!-- /.update-ctc-call-date-importer-email -->

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="ATNs"
                            :fetch="handleExcelExportSubmitClick"
                            name="atns.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import ReportMixin from "./ReportMixin"
    import ExcelExportMixin from "./ExcelExportMixin";
    import Vue from "vue"
    import UpdateCtcCallMixin from "./clients/UpdateCtcCallMixin";
    import UpdateDDCallMixin from './clients/UpdateDDCallMixin';
    import UpdateReexportCallMixin from './clients/UpdateReexportCallMixin';
    import UpdateCallOffDateMixin from './clients/UpdateCallOffDateMixin';
    import JsonExcel from "vue-json-excel"

    const FORM_STATE = {
        id: null,
        order_code: null,
        brand_id: null,
        model_id: null,
        factory_reference_number: null,
        version_type: null,
        category: null,
        delivery_place_importer: null,
        second_delivery_place_importer: null,
        vin_number: null,
        fuel_type: null,
        order_type: null,
        mpo_number: null,
        order_country: null,
        destination_country_importer: null,
        invoice_number_importer: null,
        dealer_id: null,
        delivery_place_dealer: null,
        dealer_name: null,
        dealer_zone: null,
        destination_country_dealer: null,
        model_description: null,
        expected_production_date: null,
        actual_production_date: null,
        manufacturer_invoice_date: null,
        call_off_date_importer: null,
        reexport_call_off_date_importer: null,
        invoice_date_importer: null,
        status: null,
        order_error_notes: '.',
        admin: 1,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        vin_number: null,
        from_date: null,
        to_date: null,
        clients: [],
        countries: [],
        orderType: [],
        orderCode: [],
        brandName: [],
        modelCode: [],
        versionCode: [],
        status: [],
        loadStatus: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.dealerId'),
            key: 'dealer_id',
            sortable: true,
            stickyColumn: true,
            sortKey: 'dealer_id',
        },
        {
            label: self.$t('column.client'),
            key: 'client_id',
            sortable: true,
            sortKey: 'client_id',
        },
        {
            label: self.$t('column.market'),
            key: 'order_country',
            sortable: true,
            sortKey: 'order_country',
        },
        {
            label: self.$t('column.orderType'),
            key: 'order_type',
            sortable: true,
            sortKey: 'order_type',
        },
        {
            label: self.$t('column.orderCode'),
            key: 'order_code',
            sortable: true,
            sortKey: 'order_code',
        },
        {
            label: self.$t('column.brandName'),
            key: 'brand_name',
            sortable: true,
            sortKey: 'brand_name',
        },
        {
            label: self.$t('column.model'),
            key: 'model_code',
            sortable: true,
            sortKey: 'model_code',
        },
        {
            label: self.$t('column.versionType'),
            key: 'version_type',
            sortable: true,
            sortKey: 'version_type',
        },
        {
            label: self.$t('column.lastUpdatedAt'),
            key: 'updated_at',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
            sortKey: 'status',
        },
        {
            label: self.$t('column.loadStatus'),
            key: 'load_status',
            sortable: false,
        },
        (self.$global.hasAnyPermission(['ordersupdate', 'ordersview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right',
                key: 'action',
                width: 150,
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ReportMixin, ExcelExportMixin, UpdateDDCallMixin, UpdateReexportCallMixin, UpdateCallOffDateMixin, UpdateCtcCallMixin],
        components: {
            Datepicker,
            Detail,
            Treeselect,
            JsonExcel
        },
        data() {
            return {
                operationTitle: 'title.orderDrafts',
                formFields: {...FORM_STATE},
                editOrderDraft: null,
                filters: {...FILTER_STATE},
                listUrl: 'order/drafts',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    clients: [],
                    brands: [],
                    models: [],
                    dealers: [],
                    countries: [],
                    dealersLocations: [],
                    manufacturerLocations: [],
                    supplierCompoundLocations: [],
                    orderType: [
                        {id: 1, label: this.$t('title.forACustomer')},
                        {id: 2, label: this.$t('title.forAStock')},
                    ],
                    orderCode: [],
                    modelCode: [],
                    versionCode: [],
                    status: [
                        {id: 1, label: this.$t('title.pending')},
                        {id: 2, label: this.$t('title.imported')},
                    ],
                    loadStatus: [
                        {id: -3, label: this.$t('title.pending')},
                        {id: -2, label: this.$t('title.qualityHoldBlocked')},
                        {id: -1, label: this.$t('title.confirmationOrder')},
                        {id: 1, label: this.$t('title.produced')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    brandName: [],
                },
                show: true,
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.orderDrafts')
            this.getClients();
            this.getDealers();
            this.getBrands();
            this.getModels();
            this.getCountries();
            this.getManufacturerLocations();
            this.getSupplierCompoundLocations();
            this.handleReportFilterSubmit();
            this.getOrderDraftOrderCode();

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                // this.operationTitle = (operation === 'add' ? 'title.addBrand' : 'title.editBrand')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: 'order/drafts/update',
                        method: 'post',
                        data: this.formFields,
                    })

                    this.itemUpdated()
                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/order/drafts/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editOrderDraft')

                    const {data} = response
                    const {client} = data
                    const brand = _.find(this.dropdowns.brands, (item) => (_.lowerCase(item.label) === _.lowerCase(data.brand_name)));
                    const model = _.find(this.dropdowns.models, {label: `${data.model_code} - ${data.version_type}`});
                    const fromLocation = _.find(this.dropdowns.manufacturerLocations, {location_code: data.factory_reference_number});
                    const toLocation = _.find(this.dropdowns.supplierCompoundLocations, {location_code: data.delivery_place_importer});
                    const orderCountry = _.find(this.dropdowns.countries, {label: data.order_country});
                    const destinationCountryImporter = _.find(this.dropdowns.countries, {label: data.destination_country_importer});
                    const destinationCountryDealer = _.find(this.dropdowns.countries, {label: data.destination_country_dealer});
                    delete data.manufacturerLocations
                    delete data.supplierCompoundLocation
                    delete data.pickUpLocation
                    delete data.ccSupplierCompoundLocation
                    delete data.dealerLocation
                    delete data._destination_country_dealer
                    delete data._order_country

                    this.formFields = {
                        ...data,
                        id: data.id,
                        client_id: (client ? client.id : null),
                        brand_id: (brand ? brand.id : null),
                        model_id: (model ? model.id : null),
                        dealer_id: data.dealer_id,
                        status: data.status,
                        version_type: (data.version_type ? data.version_type : (model ? model.version_code : null)),
                        factory_reference_number: (fromLocation ? fromLocation.id : null),
                        delivery_place_importer: (toLocation ? toLocation.id : null),
                        order_country: (orderCountry ? orderCountry.id : null),
                        destination_country_importer: (destinationCountryImporter ? destinationCountryImporter.id : null),
                        destination_country_dealer: (destinationCountryDealer ? destinationCountryDealer.id : null),
                    }

                    this.editOrderDraft = data;
                    if (data.dealer_id) {
                        this.formFields.edit_dealer_location_id = data.delivery_place_dealer;
                    }

                    this.handleDealersSelect()
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data
                    this.dropdowns.brandName = data.map((item) => {
                        return {
                            'id': item.label,
                            'label': item.label
                        }
                    })
                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({
                        ...item,
                        label: `${item.label} - ${item.version_code}`,
                    }))

                    this.dropdowns.modelCode = data.map((item) => {
                        return {
                            id: item.label,
                            label: item.label,
                        }
                    })

                    this.dropdowns.versionCode = data.map((item) => {
                        return {
                            id: item.version_code,
                            label: item.version_code,
                        }
                    })

                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.modelCode = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getClients() {
                try {
                    const response = await request({
                        url: '/dropdowns/clients',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.clients = data

                } catch (e) {
                    this.dropdowns.clients = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = _.map(data, (item) =>
                        ({
                            ...item,
                            id: item.dealer_id,
                            label: item.label,
                        })
                    )
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getManufacturerLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/manufacturer/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.manufacturerLocations = _.map(data, (item) =>
                        ({
                            ...item,
                            id: item.location_code,
                            label: `${item.label} - ${item.name}`,
                        })
                    )

                } catch (e) {
                    console.log(e)
                    this.dropdowns.manufacturerLocations = []
                }
            },
            async getSupplierCompoundLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/supplier/compound/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompoundLocations = _.map(data, (item) =>
                        ({
                            ...item,
                            id: item.location_code,
                            label: `${item.label} - ${item.name}`,
                        })
                    )

                } catch (e) {
                    this.dropdowns.supplierCompoundLocations = []
                }
            },
            async getOrderDraftOrderCode() {
                try {
                    const response = await request({
                        url: '/dropdowns/order/drafts/order/code',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.orderCode = data
                } catch (e) {
                    this.dropdowns.orderCode = []
                }
            },
            handleLoadStatusClick(item) {
                if(!item.current_operation_object_id) {
                    return;
                }

                if(_.includes(['inbound_load', 'cc_inbound_load', 'spot_inbound_load'], item.current_operation_name)) {
                    return this.$router.push(`/inbound/loads?page=1&perPage=100&sortOrder=&sortField=&operation=detail&oToken=${item.current_operation_object_id}`)
                }

                if(_.includes(['spot_load'], item.current_operation_name)) {
                    this.$router.push(`/spot/loads?page=1&perPage=100&sortOrder=&sortField=&operation=detail&oToken=${item.current_operation_object_id}`)
                }

                if(_.includes(['dealer_to_dealer_load'], item.current_operation_name)) {
                    this.$router.push(`/dealer/to/dealer/loads?page=1&perPage=100&sortOrder=&sortField=&operation=detail&oToken=${item.current_operation_object_id}`)
                }

                if(_.includes(['reexport_load'], item.current_operation_name)) {
                    this.$router.push(`/reexport/loads?page=1&perPage=100&sortOrder=&sortField=&operation=detail&oToken=${item.current_operation_object_id}`)
                }
            },
            handleDealersSelect() {
                const self = this
                self.dropdowns.dealersLocations.length = 0;
                self.formFields.dealer_location_id = null;
                setTimeout(() => {
                    const locations = []
                    self.formFields.dealer_location_id = null;
                    const dealer = _.find(self.dropdowns.dealers, {dealer_id: self.formFields.dealer_id});

                    if (dealer) {
                        dealer.locations.map((lc) => {
                            locations.push({...lc, id: lc.location_code, label: lc.label})
                        })
                    }

                    self.dropdowns.dealersLocations = locations
                    self.formFields.dealer_location_id = self.formFields.edit_dealer_location_id
                    self.formFields.edit_dealer_location_id = null
                }, 500)
            },
            hasListAccess() {
                return this.$global.hasPermission('orderdraftsview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleBrandSelect() {
                this.formFields.model_id = null
            },
            handleModelSelect(node, instanceId) {
                this.formFields.version_type = null

                if (node) {
                    this.formFields.version_type = node.version_code
                }
            },
            refreshList() {
                this.loadList(this.listQueryParams)
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }

    Vue.component('country-sum', {
        template: '<span>({{ sum }})</span>',
        props: ['country'],
        data() {
            return {sum: 0}
        },
        watch: {
            country: {
                immediate: true,
                handler(val) {
                    _.map(val.items, (val1, ind1) => {
                        _.map(val1.items, (val2, ind2) => {
                            _.map(val2.items, (val3, ind3) => {
                                this.sum += val3.total_orders
                            })
                        })
                    })
                }
            }
        }
    });

    Vue.component('pick-up-sum', {
        template: '<span>({{ sum }})</span>',
        props: ['item'],
        data() {
            return {sum: 0}
        },
        watch: {
            item: {
                immediate: true,
                handler(val) {
                    _.map(val, (val1, ind1) => {
                        _.map(val1.items, (val2, ind2) => {
                            this.sum += val2.total_orders
                        })
                    })
                }
            }
        }
    });
</script>
<style lang="css">
    .nav.nav-pills.card-header-pills.nav-fill {
        border: 1px solid #e2e2e2;
    }

    .card-header {
        background-color: rgba(0, 0, 0, .03);
    }

    .nav-item {
        background-color: white;
    }

    .text-size-1-2em {
        font-size: 1.2em;
    }

    .collapsed .when-open,
    .not-collapsed .when-closed {
        display: none;
    }

    .fs-13 {
        font-size: 13px;
    }

    .m-h-150{
        min-height: 150px;
    }
</style>
